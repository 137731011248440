/*
 * @Author: 王时霞
 * @Date: 2021-08-28 11:33:54
 * @LastEditors: 王时霞
 * @LastEditTime: 2025-02-10 11:54:44
 * @Description: file content
 * @FilePath: \yuyue.keenkq.com\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';
import axios from 'axios'
import 'muse-ui-message/dist/muse-ui-message.css';
import Message from 'muse-ui-message'
import Toast from 'muse-ui-toast';
import WXConfig from './assets/js/wxshare.js' // 微信分享
import VueClipboard from 'vue-clipboard2'//黏贴板
import Loading from 'muse-ui-loading';
import 'muse-ui-loading/dist/muse-ui-loading.css'; // load css
import Vant from 'vant';
import 'vant/lib/index.css';
import dayjs from 'dayjs' // ES 2015

Vue.prototype.$dayjs = dayjs()//挂在到vue的原型对象上，这样可在全局中使用


Vue.use(Vant);
Vue.use(Loading);
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI);

Vue.use(VueClipboard)
Vue.use(Toast,{
  position: 'top', 
});
Vue.use(Message);
Vue.use(MuseUI);
Vue.use(require('vue-wechat-title'))

Vue.prototype.$http = axios
Vue.prototype.WXConfig = WXConfig;
axios.defaults.baseURL = 'https://yuyueapi.itkeen.com/';
// axios.defaults.baseURL = 'http://yuyueapi.keen.com/';
axios.interceptors.request.use((config)=>{
  config.headers.common['Authorization']='Bearer '+localStorage.token;
  return config;
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
